import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout/layout";
import config from "../../data/SiteConfig";
import Img from "gatsby-image";
import ImageWithCaption from "../components/ImageWithCaption/ImageWithCaption";
import {
  Container,
  Columns,
  Column,
  Content,
  Section,
  Breadcrumb,
  BreadcrumbItem,
  Icon,
} from "bloomer";

const moment = require("moment");

export default class ArticleTemplate extends React.Component {
  render() {
    const article = this.props.data.markdownRemark;
    const formattedDate = moment(article.frontmatter.date).fromNow(); // April 28th 2020, 11:21:25 am

    return (
      <Layout>
        <Helmet>
          <title>{`${article.frontmatter.title} | ${config.siteTitle}`}</title>
          <meta name="title" content={article.frontmatter.title}></meta>
          <meta name="description" content={article.frontmatter.teaser}></meta>

          <meta property="og:title" content={article.frontmatter.title} />
          <meta property="og:type" content="article" />
          <meta
            property="og:image"
            content={
              article.frontmatter.featureImage.file.childImageSharp.fixed.src
            }
          />
          
          <meta
            property="og:description"
            content={article.frontmatter.teaser}
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@dark_inventions" />
          <meta name="twitter:creator" content="@dark_inventions" />
          <meta name="twitter:title" content={article.frontmatter.title}></meta>
          <meta
            name="twitter:description"
            content={article.frontmatter.teaser}
          ></meta>
          <meta
            name="twitter:image"
            content={
              `https://www.darkinventions.co.uk${article.frontmatter.featureImage.file.childImageSharp.fixed.src}`
            }
          ></meta>
        </Helmet>
        <Section className="has-navbar-padding">
          <Container>
            <hr />
            <Breadcrumb>
              <ul>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/discover">Discover</Link>
                </BreadcrumbItem>
                <BreadcrumbItem isActive>
                  <a>{article.frontmatter.title}</a>
                </BreadcrumbItem>
              </ul>
            </Breadcrumb>
            <Columns isMultiline>
              <Column isSize={{ tablet: 12, desktop: 4, widescreen: 3 }}>
                <span class="tag article-tag is-dark">{article.frontmatter.type}</span>
                <h1 class="title is-1">{article.frontmatter.title}</h1>
                <hr />
                <p class="subtitle is-4">{article.frontmatter.teaser}</p>
                <dl>
                  <dt>Published</dt>
                  <dd>{formattedDate}</dd>

                  <dt>Time to read</dt>
                  <dd>About {article.timeToRead} minutes</dd>
                  <dd></dd>
                </dl>
              </Column>

              <Column isSize={{ tablet: 12, desktop: 8, widescreen: 9 }}>
                <Container>
                  <ImageWithCaption
                    fluid={
                      article.frontmatter.featureImage.file.childImageSharp
                        .fluid
                    }
                    caption={article.frontmatter.featureImage.caption}
                  />
                  <Columns>
                    <Column
                      isSize={{ tablet: 8, desktop: 12, widescreen: 8 }}
                      isCentered
                    >
                      <Content
                        className="articleContent"
                        dangerouslySetInnerHTML={{ __html: article.html }}
                      ></Content>
                    </Column>
                  </Columns>
                </Container>
              </Column>
            </Columns>
          </Container>
        </Section>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
         query ArticleBySlug($slug: String!) {
           markdownRemark(fields: { slug: { eq: $slug } }) {
             html
             timeToRead
             excerpt
             fields {
               slug
               id
             }
             frontmatter {
               title
               type
               teaser
               date
               featureImage {
                 caption
                 file {
                   childImageSharp {
                     fluid(maxWidth: 1920, maxHeight: 1080) {
                       ...GatsbyImageSharpFluid
                     }
                     fixed(width:1200) {
                       ...GatsbyImageSharpFixed
                     }
                   }
                 }
               }
             }
           }
         }
       `;
