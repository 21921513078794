import React from "react";
import Img from "gatsby-image";
import styles from "./ImageWithCaption.scss";

export default class NewsListItem extends React.Component {
    render() {
        const imageFileFluid = this.props.fluid;
        const imageCaption = this.props.caption;

        return (
            <div className='imageWithCaption'>
        <Img fluid={imageFileFluid} />
        <p className="caption">{imageCaption}</p>
      </div>
    );
  }
}
